<template>
  <div class="no-drawn">
    <transition name="fade"
                appear>
      <div class="no-drawn-statistics"
           v-if="active">
        <StatisticsNumbers :numbers="getNumbers"
                           :title="`${length} ${$store.getters.translate(
                             statistics[activeStatistics].title)}`"
                           :type="statistics[activeStatistics].type"/>
      </div>
    </transition>
    <div class="loaders-wrapper">
      <Loaders :is-results-data-valid="isResultsDataValid"
               :start-time="$store.getters.nextEventTime || 0"/>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import take from 'lodash/take';
import StatisticsNumbers from '@/components/StatisticsNumbers';
import Loaders from '@/components/Loaders';

export default {
  name: 'NoDrawn',
  data() {
    return {
      active: true,
      activeStatistics: 0,
      length: 4,
      statistics: [
        {
          title: 'leastDrawn',
          type: 'cold',
          getter: 'leastDrawnNumbers',
        },
        {
          title: 'mostDrawn',
          type: 'hot',
          getter: 'mostDrawnNumbers',
        },
        {
          title: 'leastPlayed',
          type: 'cold',
          getter: 'leastPlayedNumbers',
        },
        {
          title: 'mostPlayed',
          type: 'hot',
          getter: 'mostPlayedNumbers',
        },
      ],
      timeout: null,
    };
  },
  props: {
    isResultsDataValid: {
      type: Boolean,
      default: true,
    },
  },
  components: { Loaders, StatisticsNumbers },
  computed: {
    ...mapGetters([
      'mostPlayedNumbers',
      'leastPlayedNumbers',
      'mostDrawnNumbers',
      'leastDrawnNumbers',
    ]),
    getNumbers() {
      const { getter } = this.statistics[this.activeStatistics];
      return take(this[getter], this.length);
    },
  },
  methods: {
    handleNoDrawn() {
      setTimeout(() => {
        this.active = false;
        setTimeout(() => {
          this.activeStatistics = this.activeStatistics === 3 ? 0 : this.activeStatistics + 1;
          this.active = true;
        }, 1000);
        this.handleNoDrawn();
      }, 5000);
    },
  },
  mounted() {
    this.handleNoDrawn();
  },
};
</script>

<style lang="scss" scoped>
  .no-drawn {
    align-items: center;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    opacity: 1;
    position: absolute;
    right: 0;
    top: 0;
    .no-drawn-statistics {
      width: 191px;
    }
    .loaders-wrapper {
      bottom: 0;
      color: #fff;
      left: 0;
      position: absolute;
      right: 0;
    }
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity 1s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
</style>
