<template>
  <div class="basic-layout">
    <article class="main"
             :style="[$store.getters.screenSize < 730 ? {
               justifyContent: !startNextStatus && !newDrawnCounter ? 'space-between' : 'center',
             } : {}]"
             :class="{'blur': startNextStatus}">
      <StatisticsNumbersBox v-if="$store.getters.screenSize >= 730"/>
      <TemporaryDrawnNumber :temp-drawn-number="tempDrawnNumber"/>
      <DrawnNumbersBox v-if="getStatus"/>
    </article>
    <NoDrawn :is-results-data-valid="isResultsDataValid"
             v-if="startNextStatus && !newDrawnCounter"/>
    <NewDrawnCounter v-if="newDrawnCounter"/>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { slice, range } from 'lodash';
import StatisticsNumbersBox from '@/components/StatisticsNumbersBox';
import DrawnNumbersBox from '@/components/DrawnNumbersBox';
import TemporaryDrawnNumber from '@/components/TemporaryDrawnNumber';
import { calcDrawnNumbers } from '@/utility';
import NoDrawn from '@/components/NoDrawn';
import EventBus from '@/store/EventBus';
import NewDrawnCounter from '@/components/NewDrawnCounter';

export default {
  name: 'BasicLayout',
  components: {
    NewDrawnCounter,
    NoDrawn,
    TemporaryDrawnNumber,
    DrawnNumbersBox,
    StatisticsNumbersBox,
  },
  data() {
    return {
      tempDrawnNumber: null,
      tempDrawnNumberIndex: null,
      startNextStatus: false,
      layoutMount: false,
      newResultsDelay: 20000,
    };
  },
  computed: {
    ...mapGetters([
      'results',
      'eventTime',
      'maxRegular',
      'drawnResults',
      'newDrawnCounter',
      'screenSize',
      'isResultsDataValid',
    ]),
    getStatus() {
      let status = true;
      if (this.startNextStatus && this.screenSize < 730) {
        status = false;
      }
      return status;
    },
  },
  methods: {
    // eslint-disable-next-line no-undef
    ...mapActions([
      'setDrawnResults',
      'updateDrawnResults',
      'fetchStatistics',
      'fetchResults',
      'setNewDrawnCounter',
    ]),
    setTempDrawnNumber(number) {
      this.$set(this, 'tempDrawnNumber', number);
    },
    updateTempDrawnNumber() {
      let next = null;
      this.setTempDrawnNumber(null);
      const drawnNumbersLength = this.drawnResults.length;
      if (this.results) {
        next = drawnNumbersLength !== this.results.length
          ? this.results[drawnNumbersLength] : null;
      }
      if (next) {
        setTimeout(() => {
          this.setTempDrawnNumber(next);
          this.updateDrawnResults(next);
        }, 500);
        // setTimeout(() => {
        //   this.updateDrawnResults(this.tempDrawnNumber);
        // }, 1990);
        this.callUpdateTempDrawnNumber();
      } else {
        const deleay = this.results ? 10000 : 100;
        setTimeout(() => {
          this.startNextStatus = true;
          this.setDrawnResults([]);
        }, deleay);
      }
    },
    callUpdateTempDrawnNumber() {
      setTimeout(() => {
        this.updateTempDrawnNumber();
      }, 2000);
    },
  },
  async mounted() {
    this.$nextTick(() => {
      this.layoutMount = true;
    });
    EventBus.$on('new-event-starting', () => {
      this.fetchStatistics();
      setTimeout(() => {
        this.fetchResults().then((resp) => {
          if (Object.keys(resp).length) {
            this.setNewDrawnCounter(true);
            setTimeout(() => {
              this.callUpdateTempDrawnNumber();
              this.startNextStatus = false;
              this.setNewDrawnCounter(false);
            }, 5000);
          }
        }).catch(() => {});
      }, this.newResultsDelay);
    });
    const drawnNumber = await calcDrawnNumbers(this.eventTime, 2, this.results);
    let numbers = null;
    if (drawnNumber >= this.maxRegular) {
      numbers = this.results || range(20);
      this.setDrawnResults(numbers);
      this.updateTempDrawnNumber();
    } else {
      numbers = slice(this.results, 0, drawnNumber);
      this.setDrawnResults(numbers);
      this.setTempDrawnNumber(numbers[numbers.length - 1]);
      this.callUpdateTempDrawnNumber();
    }
  },
};
</script>

<style lang="scss">
  .basic-layout {
    background-color: #000;
    height: 276px;
    position: relative;
    @media screen and (min-width: 730px){
      height: 250px;
      .main {
        justify-content: space-between;
      }
    }
    .main {
      align-items: center;
      background: url('../assets/images/balls.jpg') no-repeat;
      background-size: cover;
      display: flex;
      height: 100%;
      padding: 8px;
      transition: all 1.5s linear;
      &.blur {
        filter: blur(15px);
      }
      @media screen and (max-width: 729px) {
        flex-direction: column;
        .temporary-drawn-number {
          align-self: center;
        }
      }
    }
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
</style>
